@import url("https://fonts.googleapis.com/css?family=Montserrat:700|Open+Sans&display=swap");
html {
  scroll-behavior: smooth;
}
body {
  display: flex;
  align-content: center;
  justify-content: center;
  background: #fff;
  font-size: 10px;
  font-family: "Open Sans";
  box-sizing: border-box;
  background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}
.App {
  height: 100vh;
  width: 80vw;
  display: flex;
  flex-direction: column;
}

a {
  text-decoration: none;
  color: black;
}

/* Header */

.header_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80vw;
  top: 5rem;
  position: relative;
}
.header_container h1 {
  font-size: 1rem;
}
.header_container ul {
  list-style: none;
}
.header_container ul li {
  display: inline-block;
  padding-right: 2rem;
}
.header_container ul li a {
  text-decoration: none;
  color: black;
  font-size: 1rem;
}

/* Home */
.home_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  top: 12rem;
}

.home_container .home_head_wrapper {
  position: relative;
  top: 2rem;
  display: flex;
  flex-direction: column;
  width: 40vw;
}
.home_container .home_head_wrapper h1 {
  font-size: 4.5rem;
  font-family: "Montserrat";
}
.home_container .home_head_wrapper h1 span {
  color: #4c765b;
}
.home_container .home_head_wrapper p {
  position: relative;
  top: -4rem;
  font-size: 2rem;
  opacity: 0.4;
}

.home_container .image_container img {
  position: relative;
  height: 26rem;
  width: 18rem;
  top: 5rem;
  border-style: solid;
  border-width: 5px;
  border-top: 0px;
  border-left: 0px;
  border-color: #2b0ed0;
  transition: transform 0.5s ease;
}

.home_container .image_container img:hover {
  transform: scale(1.1);
}

/* Work */
.work_container {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 20rem;
  width: 80vw;
}
.work_container h1 {
  font-size: 5rem;
  font-family: "Montserrat";
}
.work_container .projects_container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.project {
  width: calc(100% - 69%);
}
.work_container .projects_container img {
  height: 30rem;
  width: 100%;
  transition: transform 0.5s ease;
}

.work_container .projects_container img:hover {
  opacity: 0.8;
  transform: scale(0.9);
}

.work_container .projects_container .title {
  font-size: 1.2rem;
}

.git-color {
  color: rgb(67, 67, 182);
}

/* Contact */

.contact_container {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 25rem;
}
.contact_container h1 {
  font-size: 7rem;
  font-family: "Montserrat";
}
.contact_container p {
  width: 50vw;
  font-size: 2rem;
  position: relative;
  top: -6rem;
  opacity: 0.4;
}
.contact_container .email_link {
  font-size: 2rem;
  color: rgb(123, 138, 95);
  position: relative;
  top: -8rem;
}

.contact_container ul {
  list-style-type: none;
  position: relative;
  padding-top: 1rem;
  
  
}
.contact_container li {
  display: inline;
  padding-right: 3rem;
}
.contact_container li a {
  color: black;
  text-decoration: none;
  font-size: 2rem;

}

/* About */

.spaceAbout {
  height: 20mm;
}

.about_container {
  display: flex;
  flex-direction: column;
  width: 80vw;
  justify-content: center;
  position: relative;
  top: 10rem;
}
.about_container h1 {
  font-size: 5rem;
  font-family: "Montserrat";
}
.about_container p {
  position: relative;
  top: -2rem;
  font-size: 1.6rem;
  width: 60vw;
  opacity: 0.4;
}
.about_container .about_contact {
  position: relative;
  top: -20rem;
}

@media only screen and (max-width: 600px) {
  .space {
    height: 20mm;
  }

  .spaceAbout {
    height: 20mm;
  }

  body {
    font-size: 2px;
  }
  /* Header */

  .header_container {
    width: 80vw;
    top: 2rem;
  }

  .header_container h1 {
    font-size: 1rem;
  }

  .header_container ul li {
    padding-right: 1rem;
  }

  /* Home */

  .home_container {
    top: 6rem;
  }

  .home_container .home_head_wrapper {
    top: 1.5rem;
    width: 60vw;
  }

  .home_container .home_head_wrapper h1 {
    font-size: 1.2rem;
  }
  .image img {
    width: 100%;
  }
  .home_container .home_head_wrapper p {
    top: 0rem;
    font-size: 0.7rem;
  }

  .home_container .image_container img {
    height: 10rem;
    width: 8rem;
    top: 2rem;
  }

  /* Work */
  .work_container {
    top: 10rem;
    width: 80vw;
  }

  .work_container h1 {
    font-size: 2rem;
  }

  .work_container .projects_container {
    grid-gap: 0.5rem;
  }

  .work_container .projects_container img {
    height: 8rem;
  }

  .work_container .projects_container .title {
    font-size: 0.8rem;
  }

  /* Contact */

  .contact_container {
    top: 22.5rem;
  }

  .contact_container h1 {
    font-size: 2rem;
  }

  .contact_container p {
    font-size: 0.6rem;
    top: -1rem;
  }

  .contact_container .email_link {
    font-size: 1rem;
    top: 0rem;
  }

  .contact_container li {
    padding-right: 1rem;
    
  }

  .contact_container li a {
    font-size: 0.5rem;
  }
}

@media only screen and (min-width: 600px) and (max-width: 800px) {
  .space {
    height: 20mm;
  }

  .spaceAbout {
    height: 20mm;
  }

  body {
    font-size: 2px;
  }

  /* Header */

  .header_container {
    width: 80vw;
    top: 2rem;
  }

  .header_container h1 {
    font-size: 1rem;
  }

  .header_container ul li {
    padding-right: 1rem;
  }

  /* Home */

  .home_container {
    top: 6rem;
  }

  .home_container .home_head_wrapper {
    top: 1.5rem;
    width: 60vw;
  }

  .home_container .home_head_wrapper h1 {
    font-size: 1.2rem;
  }

  .home_container .home_head_wrapper p {
    top: 0rem;
    font-size: 0.7rem;
  }

  .home_container .image_container img {
    height: 10rem;
    width: 8rem;
    top: 2rem;
  }

  .home_container .image_container img:hover {
    opacity: 0.5;
  }

  /* Work */
  .work_container {
    top: 10rem;
    width: 80vw;
  }

  .work_container h1 {
    font-size: 2rem;
  }

  .work_container .projects_container {
    grid-gap: 0.2rem;
  }

  .work_container .projects_container img {
    height: 8rem;
    width: 8rem;
  }

  .work_container .projects_container img:hover {
    opacity: 0.5;
  }

  .work_container .projects_container .title {
    font-size: 0.8rem;
  }

  /* Contact */

  .contact_container {
    top: 22.5rem;
  }

  .contact_container h1 {
    font-size: 2rem;
  }

  .contact_container p {
    font-size: 0.6rem;
    top: -1rem;
  }

  .contact_container .email_link {
    font-size: 1rem;
    top: 0rem;
  }

  .contact_container li {
    padding-right: 1rem;
  }

  .contact_container li a {
    font-size: 0.5rem;
  }

  .project {
    width: 25%;
    margin: 1rem;
  }
}
